import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Sidebar from "../components/sidebar"
import Hero from "../components/hero"
import Intro from "../components/intro"
import Contact from "../components/contact"
// import Projects from "../components/projects"
import Footer from "../components/footer"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Sidebar />
    <div className="section-cont">
      <Hero />
      <Intro />
      {/* <Projects /> */}
      <Contact />
    </div>
    <Footer />
  </Layout>
)

export default IndexPage
