import React from "react"

import "./sidebar.scss"
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

class Sidebar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
  }

  toggleSidebar = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    const { isOpen } = this.state

    return (
      <div className={`sidebar` + (isOpen ? ` open` : ``)}>
        <button className="toggle" onClick={this.toggleSidebar}>
          {isOpen ? (
            <FontAwesomeIcon icon={faXmark} />
          ) : (
            <FontAwesomeIcon icon={faBars} />
          )}
        </button>
        <div className="links">
          <a
            className="smooth-scroll"
            href="#home"
            onClick={this.toggleSidebar}
          >
            Home
          </a>
          <a
            className="smooth-scroll"
            href="#introduction"
            onClick={this.toggleSidebar}
          >
            Introduction
          </a>
          {/* <a
            className="smooth-scroll"
            href="#projects"
            onClick={this.toggleSidebar}
          >
            Projects
          </a> */}
          <a
            className="smooth-scroll"
            href="#contact"
            onClick={this.toggleSidebar}
          >
            Contact
          </a>
        </div>
      </div>
    )
  }
}

export default Sidebar
