import React from "react"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import "./footer.scss"
import socialLinks from "../assets/json/social.json"

const Footer = () => {
  return (
    <div className="footer" id="footer">
      <p>© {new Date().getFullYear()} Abdul Jalil Mian. All rights reserved.</p>
      <p>
        <OutboundLink
          href={socialLinks.linkedin}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>LinkedIn</span>
        </OutboundLink>{" "}
        |{" "}
        <OutboundLink
          href={socialLinks.github}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>Github</span>
        </OutboundLink>{" "}
        |{" "}
        <OutboundLink
          href={socialLinks.twitter}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>Twitter</span>
        </OutboundLink>
      </p>
    </div>
  )
}

export default Footer
