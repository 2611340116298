import React from "react"

import "./intro.scss"
import Section from "./section"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons"

const Intro = () => {
  return (
    <Section title="introduction">
      <div className="cstm-intro">
        <h2>
          Hello, world! I'm an enthusiastic Software Engineer based in Pakistan,
          fueled by a burning passion for developing exceptional full-stack
          applications. With over 9 years of experience, I've immersed myself in
          the ever-evolving JavaScript ecosystem, where I thrive on pushing
          boundaries and embracing new technologies and frameworks.
          <br />
          <br />
          When I'm not passionately coding away, you'll find me connecting with
          fellow tech enthusiasts, sharing knowledge, and constantly learning.
          I'm a firm believer in the power of collaboration and thrive in both
          individual and team settings, where I deliver exceptional results.
          <br />
          <br />
          If you are a like minded individual yourself, scroll down to see how
          you can reach out to me. Let's embark on a thrilling tech adventure
          together!
        </h2>
        <a
          href="https://docs.google.com/document/d/1AVgKqoRDmrp_HdpNWZBUS9we6Ic1HjoZWZB2WV0UwGg"
          target="_blank"
          rel="noopener noreferrer"
        >
          Get My Resume <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        </a>
      </div>
    </Section>
  )
}

export default Intro
