import React from "react"

import Section from "./section"
import "./contact.scss"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}
class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      submitted: false,
      submitting: false,
      formData: {},
    }
  }
  handleChange = e => {
    const name = e.target.name
    const value = e.target.value
    this.setState(oldState => {
      return {
        ...oldState,
        formData: {
          ...oldState.formData,
          [name]: value,
        },
      }
    })
  }
  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    this.setState({ submitting: true })

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state.formData,
      }),
    })
      .then(() => {
        this.setState({ submitted: true })
      })
      .catch(error => alert(error))
      .finally(() => {
        this.setState({ submitting: false })
      })
  }
  render() {
    const { submitted, submitting } = this.state
    if (submitted) {
      return (
        <Section title="contact">
          <div className="cstm-contact">
            <p>Thanks for contacting. I will get back to you shortly.</p>
          </div>
        </Section>
      )
    }
    return (
      <Section title="contact">
        <div className="cstm-contact">
          <p>
            Leave a message below and I will get back to you as soon as possible
          </p>
          <form
            name="contact"
            method="POST"
            netlify-honeypot="bot-field"
            data-netlify="true"
            onSubmit={this.handleSubmit}
            disabled={submitted}
          >
            <p className="hidden">
              <input
                name="bot-field"
                placeholder="Don’t fill this out if you're human"
              />
            </p>
            <p>
              <input
                name="name"
                placeholder="Name"
                type="text"
                onChange={this.handleChange}
                required
              />
            </p>
            <p>
              <input
                name="email"
                placeholder="Email Address"
                type="email"
                onChange={this.handleChange}
                required
              />
            </p>
            <p>
              <textarea
                name="message"
                placeholder="Message"
                onChange={this.handleChange}
                required
              ></textarea>
            </p>
            <p>
              <button type="submit" disabled={submitted || submitting}>
                {(submitted && "Submitted") ||
                  (submitting && "Submitting...") ||
                  "Submit"}
              </button>
            </p>
          </form>
        </div>
      </Section>
    )
  }
}

export default Contact
