import React from "react"
import { string } from "prop-types"

const Section = ({ title, children }) => {
  return (
    <div className="section" id={title}>
      <div className="container">
        <h2 className="sec-title">{title}</h2>
        {children}
      </div>
    </div>
  )
}

Section.defaultProps = {
  title: "",
}

Section.propTypes = {
  title: string,
}

export default Section
