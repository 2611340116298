import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowDown } from "@fortawesome/free-solid-svg-icons"
import {
  faLinkedin,
  faXTwitter,
  faGithub,
} from "@fortawesome/free-brands-svg-icons"
import socialLinks from "../assets/json/social.json"

import "./hero.scss"

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "hero-bg.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1060) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className="section" id="home">
      <div className="cstm-slider">
        <div className="slider-section left">
          <div className="bio-cont">
            <div className="bio">
              <p className="text-title">Abdul Jalil Mian</p>
              <p className="text-desc">Engineer</p>
              <p className="text-desc">Entrepreneur</p>
              <p className="text-desc">Tech Enthusiast</p>
            </div>
            <a className="more-info smooth-scroll" href="#introduction">
              <FontAwesomeIcon icon={faArrowDown} />
              <p>More information</p>
            </a>
          </div>
        </div>
        <div className="slider-section right hide-on-mobile">
          <div className="picture">
            <Img fluid={data.file.childImageSharp.fluid} />
          </div>
          <div className="social">
            <OutboundLink
              href={socialLinks.linkedin}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="social-btn">
                <FontAwesomeIcon icon={faLinkedin} />
              </div>
            </OutboundLink>
            <OutboundLink
              href={socialLinks.github}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="social-btn">
                <FontAwesomeIcon icon={faGithub} />
              </div>
            </OutboundLink>
            <OutboundLink
              href={socialLinks.twitter}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="social-btn">
                <FontAwesomeIcon icon={faXTwitter} />
              </div>
            </OutboundLink>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
